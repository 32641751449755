var _navigator, _navigator$userAgent;
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
export var isProduction = process.env.NODE_ENV === 'production';
export var isPre = process.env.NODE_ENV === 'pre';
export var isStaging = process.env.VUE_APP_ENV === 'staging';
export var isDev = process.env.NODE_ENV === 'development';
export var isTest = process.env.VUE_APP_ENV === 'test';
export var isInBrowser = typeof window !== 'undefined';
export var isKwai = (_navigator = navigator) === null || _navigator === void 0 ? void 0 : (_navigator$userAgent = _navigator.userAgent) === null || _navigator$userAgent === void 0 ? void 0 : _navigator$userAgent.includes('Kwai');
export var getEnv = function getEnv() {
  if (isStaging) {
    return 'staging';
  }
  if (isPre) {
    return 'prt';
  }
  return 'production';
};
console.log('env', process.env.NODE_ENV);