import _defineProperty from "/media/disk1/fordata/jenkins/rbdNodeJs/workspace/SRC/node_modules/@babel/runtime/helpers/esm/defineProperty";
var _timeFormatTypeMap, _timezoneMap, _currencyFormatTypeMa, _countryUnitMap, _countryCurrencyUnit;
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.split.js";
import cookies from 'js-cookie';
export var userId = cookies.get('userId') || cookies.get('ud') || '';
export var appVersion = cookies.get('appver') || '';
export var AlWAYS_OFFLINE_COUNTRY_LIST;
(function (AlWAYS_OFFLINE_COUNTRY_LIST) {
  AlWAYS_OFFLINE_COUNTRY_LIST["ARE"] = "are";
  AlWAYS_OFFLINE_COUNTRY_LIST["MYS"] = "mys";
  AlWAYS_OFFLINE_COUNTRY_LIST["KWT"] = "kwt";
  AlWAYS_OFFLINE_COUNTRY_LIST["OMN"] = "omn";
  AlWAYS_OFFLINE_COUNTRY_LIST["PHL"] = "phl";
  AlWAYS_OFFLINE_COUNTRY_LIST["SGP"] = "sgp";
  AlWAYS_OFFLINE_COUNTRY_LIST["VNM"] = "vnm";
  AlWAYS_OFFLINE_COUNTRY_LIST["RUS"] = "rus";
  AlWAYS_OFFLINE_COUNTRY_LIST["QAT"] = "qat";
  AlWAYS_OFFLINE_COUNTRY_LIST["HKG"] = "hkg";
})(AlWAYS_OFFLINE_COUNTRY_LIST || (AlWAYS_OFFLINE_COUNTRY_LIST = {}));
// 国家列表
export var COUNTRY_LIST;
(function (COUNTRY_LIST) {
  COUNTRY_LIST["BRA"] = "br";
  COUNTRY_LIST["COL"] = "col";
  COUNTRY_LIST["MEX"] = "mex";
  COUNTRY_LIST["ARG"] = "arg";
  COUNTRY_LIST["PER"] = "per";
  COUNTRY_LIST["CHL"] = "chl";
  COUNTRY_LIST["ECU"] = "ecu";
  COUNTRY_LIST["DOM"] = "dom";
  COUNTRY_LIST["TUR"] = "tur";
  // me
  COUNTRY_LIST["EGY"] = "egy";
  COUNTRY_LIST["DZA"] = "dza";
  COUNTRY_LIST["SAU"] = "sau";
  COUNTRY_LIST["MAR"] = "mar";
  COUNTRY_LIST["IRQ"] = "irq";
  COUNTRY_LIST["JOR"] = "jor";
  // snack
  COUNTRY_LIST["IDN"] = "idn";
  COUNTRY_LIST["PAK"] = "pak";
})(COUNTRY_LIST || (COUNTRY_LIST = {}));
var getQuery = function getQuery(param) {
  var urlSearchParams = new URLSearchParams(window.location.search);
  return urlSearchParams.get(param);
};
// 获取国家
var getCountry = function getCountry() {
  var webCountry = cookies.get('web_country') || getQuery('country');
  if (webCountry) {
    return webCountry;
  }
  // 客户端注入的Cookie
  var nativeCountry = (cookies.get('countryInfo') || '').toLowerCase();
  if (nativeCountry === 'bra') {
    return COUNTRY_LIST.BRA;
  }
  return nativeCountry;
};
var getlanguage = function getlanguage() {
  /**
   * 1、native language 格式：en%3Bq%3D1%2C%20zh-Hans-CN%3Bq%3D0.9
   * 2、decodeURIComponent('en%3Bq%3D1%2C%20zh-Hans-CN%3Bq%3D0.9') => "en;q=1, zh-Hans-CN;q=0.9"
   * 3、"en;q=1, zh-Hans-CN;q=0.9".split() => ["en;q=1, zh-Hans-CN;q=0.9"]
   *
   */
  var language = (getQuery('language') || navigator.language || 'en').toLowerCase();
  return language;
};
// 根据web语音获取国家，默认为巴西
export var getCountryByWebLanguage = function getCountryByWebLanguage(countries) {
  return countries.find(function (item) {
    return item.defaultLanguage === webLanguage || item.defaultLanguage.includes(webLanguage) || item.defaultLanguage.includes(webLanguage.split('-')[0]);
  }) || countries.find(function (item) {
    return item.defaultLanguage === 'pt-br';
  });
};
// 当前语言
export var webLanguage = getlanguage();
// 当前国家
export var webCountry = getCountry();
// 支持的桶列表
export var BUCKET_LIST;
(function (BUCKET_LIST) {
  BUCKET_LIST["BR"] = "br";
  BUCKET_LIST["SPA"] = "spa";
})(BUCKET_LIST || (BUCKET_LIST = {}));
// 各个国家时间展示格式列表
var timeFormatTypeMap = (_timeFormatTypeMap = {}, _defineProperty(_timeFormatTypeMap, COUNTRY_LIST.BRA, 'YYYY-MM-DD HH:mm'), _defineProperty(_timeFormatTypeMap, COUNTRY_LIST.COL, 'DD-MM-YYYY HH:mm'), _defineProperty(_timeFormatTypeMap, COUNTRY_LIST.MEX, 'DD-MM-YYYY HH:mm'), _defineProperty(_timeFormatTypeMap, COUNTRY_LIST.ARG, 'DD-MM-YYYY HH:mm'), _defineProperty(_timeFormatTypeMap, COUNTRY_LIST.PER, 'DD-MM-YYYY HH:mm'), _defineProperty(_timeFormatTypeMap, COUNTRY_LIST.CHL, 'DD-MM-YYYY HH:mm'), _defineProperty(_timeFormatTypeMap, COUNTRY_LIST.ECU, 'DD-MM-YYYY HH:mm'), _defineProperty(_timeFormatTypeMap, COUNTRY_LIST.DOM, 'DD-MM-YYYY HH:mm'), _defineProperty(_timeFormatTypeMap, COUNTRY_LIST.TUR, 'DD-MM-YYYY HH:mm'), _defineProperty(_timeFormatTypeMap, COUNTRY_LIST.EGY, 'DD-MM-YYYY HH:mm'), _defineProperty(_timeFormatTypeMap, COUNTRY_LIST.DZA, 'DD-MM-YYYY HH:mm'), _defineProperty(_timeFormatTypeMap, COUNTRY_LIST.SAU, 'DD-MM-YYYY HH:mm'), _defineProperty(_timeFormatTypeMap, COUNTRY_LIST.MAR, 'DD-MM-YYYY HH:mm'), _defineProperty(_timeFormatTypeMap, COUNTRY_LIST.IRQ, 'DD-MM-YYYY HH:mm'), _defineProperty(_timeFormatTypeMap, COUNTRY_LIST.JOR, 'DD-MM-YYYY HH:mm'), _defineProperty(_timeFormatTypeMap, COUNTRY_LIST.IDN, 'DD-MM-YYYY HH:mm'), _defineProperty(_timeFormatTypeMap, COUNTRY_LIST.PAK, 'YYYY-MM-DD HH:mm'), _timeFormatTypeMap);
// 时间展示格式
export var timeFormatType = timeFormatTypeMap[webCountry];
// 各个国家时区列表
var timezoneMap = (_timezoneMap = {}, _defineProperty(_timezoneMap, COUNTRY_LIST.BRA, 3), _defineProperty(_timezoneMap, COUNTRY_LIST.COL, 5), _defineProperty(_timezoneMap, COUNTRY_LIST.MEX, 6), _defineProperty(_timezoneMap, COUNTRY_LIST.ARG, 3), _defineProperty(_timezoneMap, COUNTRY_LIST.PER, 5), _defineProperty(_timezoneMap, COUNTRY_LIST.CHL, 4), _defineProperty(_timezoneMap, COUNTRY_LIST.ECU, 5), _defineProperty(_timezoneMap, COUNTRY_LIST.DOM, 4), _defineProperty(_timezoneMap, COUNTRY_LIST.TUR, -3), _defineProperty(_timezoneMap, COUNTRY_LIST.EGY, -3), _defineProperty(_timezoneMap, COUNTRY_LIST.DZA, -3), _defineProperty(_timezoneMap, COUNTRY_LIST.SAU, -3), _defineProperty(_timezoneMap, COUNTRY_LIST.MAR, -3), _defineProperty(_timezoneMap, COUNTRY_LIST.IRQ, -3), _defineProperty(_timezoneMap, COUNTRY_LIST.JOR, -3), _defineProperty(_timezoneMap, COUNTRY_LIST.IDN, -6), _defineProperty(_timezoneMap, COUNTRY_LIST.PAK, -5), _timezoneMap);
// 时区
export var timezone = timezoneMap[webCountry];
// 货币格式化列表
var currencyFormatTypeMap = (_currencyFormatTypeMa = {}, _defineProperty(_currencyFormatTypeMa, COUNTRY_LIST.BRA, 'pt-br'), _defineProperty(_currencyFormatTypeMa, COUNTRY_LIST.COL, 'es-co'), _defineProperty(_currencyFormatTypeMa, COUNTRY_LIST.MEX, 'es-mx'), _defineProperty(_currencyFormatTypeMa, COUNTRY_LIST.ARG, 'es-ar'), _defineProperty(_currencyFormatTypeMa, COUNTRY_LIST.PER, 'es-pe'), _defineProperty(_currencyFormatTypeMa, COUNTRY_LIST.CHL, 'es-cl'), _defineProperty(_currencyFormatTypeMa, COUNTRY_LIST.ECU, 'es-ec'), _defineProperty(_currencyFormatTypeMa, COUNTRY_LIST.DOM, 'es-do'), _defineProperty(_currencyFormatTypeMa, COUNTRY_LIST.TUR, 'tr'), _defineProperty(_currencyFormatTypeMa, COUNTRY_LIST.EGY, 'ar-eg'), _defineProperty(_currencyFormatTypeMa, COUNTRY_LIST.DZA, 'ar-dz'), _defineProperty(_currencyFormatTypeMa, COUNTRY_LIST.SAU, 'ar'), _defineProperty(_currencyFormatTypeMa, COUNTRY_LIST.MAR, 'ar'), _defineProperty(_currencyFormatTypeMa, COUNTRY_LIST.IRQ, 'ar'), _defineProperty(_currencyFormatTypeMa, COUNTRY_LIST.JOR, 'ar'), _defineProperty(_currencyFormatTypeMa, COUNTRY_LIST.IDN, 'id'), _defineProperty(_currencyFormatTypeMa, COUNTRY_LIST.PAK, 'ur'), _currencyFormatTypeMa);
export var currencyFormatType = currencyFormatTypeMap[webCountry];
export var country = getCountry();
var countryUnitMap = (_countryUnitMap = {}, _defineProperty(_countryUnitMap, COUNTRY_LIST.BRA, 'BRL'), _defineProperty(_countryUnitMap, COUNTRY_LIST.COL, 'COP'), _defineProperty(_countryUnitMap, COUNTRY_LIST.MEX, 'MXN'), _defineProperty(_countryUnitMap, COUNTRY_LIST.ARG, 'ARS'), _defineProperty(_countryUnitMap, COUNTRY_LIST.PER, 'PEN'), _defineProperty(_countryUnitMap, COUNTRY_LIST.CHL, 'CLP'), _defineProperty(_countryUnitMap, COUNTRY_LIST.ECU, 'USD'), _defineProperty(_countryUnitMap, COUNTRY_LIST.DOM, 'DOP'), _defineProperty(_countryUnitMap, COUNTRY_LIST.TUR, 'TRY'), _defineProperty(_countryUnitMap, COUNTRY_LIST.EGY, 'EGP'), _defineProperty(_countryUnitMap, COUNTRY_LIST.SAU, 'SAR'), _defineProperty(_countryUnitMap, COUNTRY_LIST.MAR, 'MAD'), _defineProperty(_countryUnitMap, COUNTRY_LIST.IRQ, 'IQD'), _defineProperty(_countryUnitMap, COUNTRY_LIST.DZA, 'DZD'), _defineProperty(_countryUnitMap, COUNTRY_LIST.JOR, 'JOD'), _countryUnitMap);
export var countryUnit = countryUnitMap[country];
// 各个国家对应的货币单位
var countryCurrencyUnit = (_countryCurrencyUnit = {}, _defineProperty(_countryCurrencyUnit, COUNTRY_LIST.BRA, 'R$'), _defineProperty(_countryCurrencyUnit, COUNTRY_LIST.COL, '$'), _defineProperty(_countryCurrencyUnit, COUNTRY_LIST.MEX, '$'), _defineProperty(_countryCurrencyUnit, COUNTRY_LIST.ARG, '$'), _defineProperty(_countryCurrencyUnit, COUNTRY_LIST.PER, 'S/'), _defineProperty(_countryCurrencyUnit, COUNTRY_LIST.CHL, '$'), _defineProperty(_countryCurrencyUnit, COUNTRY_LIST.ECU, '$'), _defineProperty(_countryCurrencyUnit, COUNTRY_LIST.DOM, 'RD$'), _defineProperty(_countryCurrencyUnit, COUNTRY_LIST.TUR, 'RD$'), _defineProperty(_countryCurrencyUnit, COUNTRY_LIST.IDN, 'Rp'), _defineProperty(_countryCurrencyUnit, COUNTRY_LIST.PAK, 'Rs'), _defineProperty(_countryCurrencyUnit, COUNTRY_LIST.EGY, 'ج.م'), _defineProperty(_countryCurrencyUnit, COUNTRY_LIST.IRQ, 'د.ع'), _defineProperty(_countryCurrencyUnit, COUNTRY_LIST.DZA, 'دج '), _defineProperty(_countryCurrencyUnit, COUNTRY_LIST.SAU, 'ريال'), _countryCurrencyUnit);
// 当前货币单位
export var currencyUnit = countryCurrencyUnit[webCountry];
var areaCodeMap = {
  br: '+55',
  col: '+57',
  mex: '+52',
  arg: '+54',
  per: '+51',
  chl: '+56',
  ecu: '+593',
  dom: '+1',
  tur: '+90',
  egy: '+20',
  dza: '+213'
};
export var areaCode = areaCodeMap[country];
var accountKeyMap = {
  br: 'KWAI_CASH',
  col: 'KWAI_PRO_CASH_COL',
  mex: 'KWAI_PRO_CASH_MEX',
  arg: 'KWAI_PRO_CASH_ARG',
  per: 'KWAI_PRO_CASH_PER',
  chl: 'KWAI_PRO_CASH_CHL',
  ecu: 'KWAI_PRO_CASH_ECU',
  dom: 'KWAI_PRO_CASH_DOM',
  // me
  tur: 'KWAI_ME_TUR_CASH',
  egy: 'KWAI_ME_CASH',
  dza: 'KWAI_ME_ALGERIA_CASH',
  sau: 'KWAI_ME_CASH_SAU'
};
var countryPciAccountKeyMap = {
  egy: 'KWAI_ME_LIVE_DIAMOND',
  sau: 'KWAI_ME_LIVE_DIAMOND',
  br: 'KWAI_PRO_LIVE_DIAMOND',
  bra: 'KWAI_PRO_LIVE_DIAMOND'
};
export var countryAccountKey = accountKeyMap[country];
var snackAccountKeyMap = {
  idn: 'SNACK_CASH_IDN',
  pak: 'SNACK_EVENT_PAK'
};
export var snackAccountKey = snackAccountKeyMap[country];
/**
 * @deprecated 请用 getCountryPciAccountKey 获取pci account key
 */
export var countryPciAccountKey = countryPciAccountKeyMap[country];
export var getCountryPciAccountKey = function getCountryPciAccountKey() {
  var customCountry = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  // 会传入 server 下发的用户国家，兜底用 cookie 里的国家
  var countryCode = customCountry.toLocaleLowerCase() || country;
  return countryPciAccountKeyMap[countryCode];
};
var giftCardKeyMap = {
  br: 'KWAI_PRO_DTONE_KEY',
  col: 'KWAI_PRO_DTONE_KEY_COL',
  mex: 'KWAI_PRO_DTONE_KEY_MEX',
  arg: 'KWAI_PRO_DTONE_KEY_ARG',
  per: 'KWAI_PRO_DTONE_KEY_PER',
  chl: 'KWAI_PRO_DTONE_KEY_CHL',
  ecu: 'KWAI_PRO_DTONE_KEY_ECU',
  dom: 'KWAI_PRO_DTONE_KEY_DOM',
  tur: 'KWAI_PRO_DTONE_KEY_TUR',
  egy: 'KWAI_ME_DTONE_KEY_EGY',
  mar: 'KWAI_ME_DTONE_KEY_MOROCCO',
  irq: 'KWAI_ME_DTONE_KEY_IRAQ',
  dza: 'KWAI_ME_DTONE_KEY_ALGERIA',
  jor: 'KWAI_ME_DTONE_KEY_JOR',
  sau: 'KWAI_ME_DTONE_KEY_SAU'
};
export var giftCardKey = giftCardKeyMap[country];
// liquido 礼品卡
var liquidoKeyMap = {
  mex: 'KWAI_PRO_LIQUIDO_KEY_MEX',
  sau: 'KWAI_ME_DTONE_GIFTCARD_KEY_SAU',
  br: 'KWAI_PRO_DTONE_GIFTCARD_KEY',
  col: 'KWAI_PRO_DTONE_GIFTCARD_KEY_COL'
};
export var liquidoKey = liquidoKeyMap[country];
// ebanx 国家代码
var ebanxCountryMap = {
  col: 'CO',
  mex: 'MX'
};
export var ebanxCountryCode = ebanxCountryMap[country];
export var isActivityCountry = function isActivityCountry() {
  return !!countryAccountKey || webCountry === COUNTRY_LIST.IRQ;
};
// 20230105 上线一些桶的激励活动钱包 https://docs.corp.kuaishou.com/k/home/VKtI0Ni_Nu-Q/fcAD2kD3fa7JTn6KJDVGxSR3D
export var offlineActivityCountry = [
// 一些一直下线的列表
COUNTRY_LIST.SAU, COUNTRY_LIST.MAR, COUNTRY_LIST.JOR, AlWAYS_OFFLINE_COUNTRY_LIST.MYS, AlWAYS_OFFLINE_COUNTRY_LIST.ARE, AlWAYS_OFFLINE_COUNTRY_LIST.KWT, AlWAYS_OFFLINE_COUNTRY_LIST.OMN, AlWAYS_OFFLINE_COUNTRY_LIST.PHL, AlWAYS_OFFLINE_COUNTRY_LIST.SGP, AlWAYS_OFFLINE_COUNTRY_LIST.VNM, AlWAYS_OFFLINE_COUNTRY_LIST.RUS, AlWAYS_OFFLINE_COUNTRY_LIST.QAT, AlWAYS_OFFLINE_COUNTRY_LIST.HKG,
// COUNTRY_LIST.CHL,
COUNTRY_LIST.DOM, COUNTRY_LIST.ECU,
// 西语四个国家下架激励活动钱包，prd https://docs.corp.kuaishou.com/d/home/fcAD8EhZhO92nIcAr-Oml_TuN
// COUNTRY_LIST.MEX,
// COUNTRY_LIST.COL,
// COUNTRY_LIST.PER,
// COUNTRY_LIST.ARG,
COUNTRY_LIST.IRQ, COUNTRY_LIST.DZA
// 20230113 埃及下线激励钱包
// 20230309 埃及上线激励钱包
// COUNTRY_LIST.EGY,
].includes(webCountry);
// shop 跳转类型
export var IJumpType;
(function (IJumpType) {
  IJumpType[IJumpType["KWAI_H5"] = 0] = "KWAI_H5";
  IJumpType[IJumpType["OUTSIDE_WEB"] = 1] = "OUTSIDE_WEB";
  IJumpType[IJumpType["LIVE"] = 2] = "LIVE";
  IJumpType[IJumpType["PHOTO"] = 3] = "PHOTO";
  IJumpType[IJumpType["HashTag"] = 4] = "HashTag";
})(IJumpType || (IJumpType = {}));
export var isEnableThirdPartyInIos = function isEnableThirdPartyInIos() {
  return [].includes(webCountry);
};
export var kwaiShopUrl = 'https://m-shop.kwai.com/shop/c/home?hyId=kibt-fe-shop-c-v1&__launch_options__=%7B%22topBarPosition%22%3A%22fixed%22%7D';
export var KWAI_FEEDBACK_URL = 'https://app.kwai.com/feedback?channel=200&hyId=feedback&__launch_options__=%7B%22topBarPosition%22%3A%22fixed%22%7D';