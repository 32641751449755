import _objectSpread from"/media/disk1/fordata/jenkins/rbdNodeJs/workspace/SRC/node_modules/@babel/runtime/helpers/esm/objectSpread2";import"core-js/modules/es.function.name.js";import"core-js/modules/es.regexp.exec.js";import"core-js/modules/es.string.split.js";import KwaiLog from"@kibt/weblogger";import cookies from"js-cookie";import{isPre,isProduction}from"@/common/env";import{init}from"@kibt/prefetch";// refer: https://component.corp.kuaishou.com/docs/weblogger/views/docs/config.html
// export const webLogger = new Weblog(logConfig as any, baseOptions);
export var kwailog=new KwaiLog({env:isProduction||isPre?"production":"development",radar:{// 雷达平台上的唯一标识
projectId:"a5e81cbd04",// 上报采样率，1 为全部上报
sampling:1,enableKtrace:true,ktraceBizName:"x6",ktraceWhitelist:[window.location.host],ktraceServiceName:"x6-kwai-wallet"}},{version_name:cookies.get("appver")||"",network_type:cookies.get("net"),channel:cookies.get("c")||"",product_name:cookies.get("kpn")||"KWAI",package_name:cookies.get("kpn")||"KWAI",user_id:cookies.get("userId")||cookies.get("ud")||"",h5_extra_attr:{county_name:{country:cookies.get("countryInfo")||"UNKNOWN",bucket:cookies.get("bucket")||"UNKNOWN"}}});export var webLogger=kwailog.weblog;init({radarCore:kwailog.radar.core});// 日志类型
var LogType;(function(LogType){// 曝光
LogType["show"]="SHOW";// 点击
LogType["click"]="CLICK";// PV
LogType["pv"]="PV";// 雷达事件
LogType["custom"]="CUSTOM"})(LogType||(LogType={}));/**
 * 生成上报参数
 * @param data 上报数据
 * @returns PVOptions | ShowOptions | TaskOptions | CustomOptions
 */var generateLogParams=function generateLogParams(data){// PV曝光其他参数暂时不支持
if(data.type==="pv"){return{type:"enter",page:data.name,params:data.value,eventId:data.eventId}}if(data.type==="custom"){return{key:data.name,value:data.value,eventId:data.eventId}}// click 和 show 事件
return{action:data.name,params:data.value,eventId:data.eventId}};/**
 * 数据上报
 * @param data 上报数据
 * @param immediate 是否立即上报
 */var logCollect=function logCollect(data,immediate){// 不存在类型则不上报
if(!(data!==null&&data!==void 0&&data.type)){return}var type=data.type;var logParams=generateLogParams(data);var logType=LogType[type];if(immediate){webLogger.sendImmediately(logType,logParams)}else{webLogger.collect(logType,logParams)}};/**
 * 收集上报埋点
 * @param data
 */export var sendLog=function sendLog(data){logCollect(data)};/**
 * 立即上报埋点
 * @param data
 */export var immediateSendLog=function immediateSendLog(data){logCollect(data,true)};/**
 * 雷达上报：自定义事件
 */export var radarLog=function radarLog(data){var duration=data.duration;try{var _webLogger$plugins;if(webLogger!==null&&webLogger!==void 0&&(_webLogger$plugins=webLogger.plugins)!==null&&_webLogger$plugins!==void 0&&_webLogger$plugins.radar){webLogger.plugins.radar.event(data,{duration:duration})}}catch(error){console.error(error)}};export var radar={// 雷达fmp上报
fmp:function fmp(){var _webLogger$plugins2,_webLogger$plugins2$r;webLogger===null||webLogger===void 0?void 0:(_webLogger$plugins2=webLogger.plugins)===null||_webLogger$plugins2===void 0?void 0:(_webLogger$plugins2$r=_webLogger$plugins2.radar)===null||_webLogger$plugins2$r===void 0?void 0:_webLogger$plugins2$r.fmp()},// 自定义时间-雷达上报
event:function event(params){var _webLogger$plugins3,_webLogger$plugins3$r;var eventName=params.eventName,_params$eventType=params.eventType,eventType=_params$eventType===void 0?"CUSTOM":_params$eventType,_params$duration=params.duration,duration=_params$duration===void 0?performance.now():_params$duration,_params$extra=params.extra,extra=_params$extra===void 0?{}:_params$extra;webLogger===null||webLogger===void 0?void 0:(_webLogger$plugins3=webLogger.plugins)===null||_webLogger$plugins3===void 0?void 0:(_webLogger$plugins3$r=_webLogger$plugins3.radar)===null||_webLogger$plugins3$r===void 0?void 0:_webLogger$plugins3$r.event({name:eventName,event_type:eventType,category:"M_Wallet",src:window.location.href.split("?")[0],extra_info:JSON.stringify(_objectSpread(_objectSpread({},extra),{},{referrer:document.referrer}))},{duration:duration})},// 自定义错误-雷达上报
error:function error(params){var _webLogger$plugins4,_webLogger$plugins4$r;var eventName=params.eventName,_params$extra2=params.extra,extra=_params$extra2===void 0?{}:_params$extra2;webLogger===null||webLogger===void 0?void 0:(_webLogger$plugins4=webLogger.plugins)===null||_webLogger$plugins4===void 0?void 0:(_webLogger$plugins4$r=_webLogger$plugins4.radar)===null||_webLogger$plugins4$r===void 0?void 0:_webLogger$plugins4$r.event({name:eventName,event_type:"CUSTOM_ERROR",category:"M_Wallet",src:window.location.href.split("?")[0],extra_info:JSON.stringify(_objectSpread(_objectSpread({},extra),{},{referrer:document.referrer}))})},// https://docs.corp.kuaishou.com/d/home/fcAB8GYHkqoiD7i_U4CVkzzHZ
actionStart:function actionStart(params){var _webLogger$plugins$ra;webLogger===null||webLogger===void 0?void 0:(_webLogger$plugins$ra=webLogger.plugins.radar)===null||_webLogger$plugins$ra===void 0?void 0:_webLogger$plugins$ra.action.start(params)},actionEnd:function actionEnd(params){var _webLogger$plugins$ra2;webLogger===null||webLogger===void 0?void 0:(_webLogger$plugins$ra2=webLogger.plugins.radar)===null||_webLogger$plugins$ra2===void 0?void 0:_webLogger$plugins$ra2.action.end(params)}};export var logger={sendPv:function sendPv(args){var page=args.page,_args$params=args.params,params=_args$params===void 0?{}:_args$params,_args$radarName=args.radarName,radarName=_args$radarName===void 0?page:_args$radarName,_args$immediately=args.immediately,immediately=_args$immediately===void 0?true:_args$immediately,_args$radar=args.radar,isSendRadar=_args$radar===void 0?true:_args$radar;// weblogger离线埋点
webLogger.send("PV",{type:"enter",page:page,params:params,contentPackage:JSON.stringify(_objectSpread(_objectSpread({},params),{},{__debugger_name__:radarName}))},immediately);// radar实时埋点，实时大盘分析
if(isSendRadar){var _webLogger$plugins5,_webLogger$plugins5$r;webLogger===null||webLogger===void 0?void 0:(_webLogger$plugins5=webLogger.plugins)===null||_webLogger$plugins5===void 0?void 0:(_webLogger$plugins5$r=_webLogger$plugins5.radar)===null||_webLogger$plugins5$r===void 0?void 0:_webLogger$plugins5$r.event({name:radarName,event_type:"CUSTOM_PV",category:"M_Wallet",extra_info:JSON.stringify(_objectSpread(_objectSpread({},params),{},{referrer:document.referrer}))})}},sendClick:function sendClick(args){var page=args.page,action=args.action,_args$params2=args.params,params=_args$params2===void 0?{}:_args$params2,radarName=args.radarName,_args$immediately2=args.immediately,immediately=_args$immediately2===void 0?true:_args$immediately2,_args$radar2=args.radar,isSendRadar=_args$radar2===void 0?true:_args$radar2;// weblogger离线埋点
webLogger.send("CLICK",{action:action,page:page,params:params,contentPackage:JSON.stringify({__debugger_name__:radarName,tag:"logger"})},immediately);// radar实时埋点，实时大盘分析
if(isSendRadar){var _webLogger$plugins6,_webLogger$plugins6$r;webLogger===null||webLogger===void 0?void 0:(_webLogger$plugins6=webLogger.plugins)===null||_webLogger$plugins6===void 0?void 0:(_webLogger$plugins6$r=_webLogger$plugins6.radar)===null||_webLogger$plugins6$r===void 0?void 0:_webLogger$plugins6$r.event({name:radarName,event_type:"CUSTOM_CLICK",category:"M_Wallet",extra_info:JSON.stringify(_objectSpread(_objectSpread({},params),{},{referrer:document.referrer}))})}},sendShow:function sendShow(args){var page=args.page,action=args.action,_args$params3=args.params,params=_args$params3===void 0?{}:_args$params3,radarName=args.radarName,_args$immediately3=args.immediately,immediately=_args$immediately3===void 0?true:_args$immediately3;webLogger.send("SHOW",{action:action,page:page,params:params,contentPackage:JSON.stringify({__debugger_name__:radarName})},immediately)}};